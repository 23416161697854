import { FANTASY } from './biwenger-helper';
import BiwengerIcon from '@/components/shared/icons/biwenger-icon';
import FantasyLaLigaIcon from '@/components/shared/icons/fantasy-la-liga-icon';
import AnaliticaFantasyIcon from '@/components/shared/icons/analitica-fantasy-icon';

const defaultImageSrc = `${process.env.NEXT_PUBLIC_IMAGES}/banners/analitica-fantasy.jpg`;
const playerSize = 133;
const playerHeightSize = 137;
const teamSize = 60;
const bigPlayerSize = 249;

export const getArticleImage = (articulo) => {
    if (articulo?.coverImage) {
        return articulo?.coverImage;
    }

    if (articulo?.bannerImage) {
        return articulo?.bannerImage;
    }

    return defaultImageSrc;
};

export const getPostCardImage = (cover, width, height) => {
    if (cover) {
        return `${cover}?width=${width * 1.5}&height=${height * 1.5}`;
    }

    return `${defaultImageSrc}?width=${width}&height=230`;
};

export const getMasterPlayerImage = (player, _, isDefault) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;

    if (player?.information?.photo) {
        return `${player?.information?.photo}${addWidth}`;
    }

    if (player?.photo) {
        return `${player?.photo}${addWidth}`;
    }

    if (isDefault && player.id) {
        return `${process.env.NEXT_PUBLIC_DEFAULT_IMAGES}/${player.id}.png${addWidth}`;
    }

    const id = player?.id;

    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${id}.png${addWidth}`;
};

export const getPlayerPhotoForceId = (player) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;
    const id = player?.information?.id ?? player?.id;
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${id}.png${addWidth}`;
};

export const getFantasyPlayerImage = (id) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores-fantasy/${id}.png${addWidth}`;
};

export const getMasterPhotoById = (id) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${id}.png${addWidth}`;
};

export const getMasterBigPhotoById = (id, width = bigPlayerSize) => {
    const addWidth = `?width=${width}&height=${width}`;
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${id}.png${addWidth}`;
};

export const getJugadoresSeleccion = (id) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;
    return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores-seleccion/${id}.png${addWidth}`;
};

export const getTeamImageById = (id, size) => {
    const addWidth = `?width=${size ?? teamSize}&height=${size ?? teamSize}`;
    return `${process.env.NEXT_PUBLIC_IMAGES}/equipos/${id}.png${addWidth}`;
};

export const getApiFootballTeamImageById = (id) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;
    return `${process.env.NEXT_PUBLIC_DEFAULT_IMAGES}/teams/${id}.png${addWidth}`;
};

export const getVenueImageById = (id) => {
    const addWidth = `?width=${teamSize}&height=${teamSize}`;
    return `${process.env.NEXT_PUBLIC_IMAGES}/venues/${id}.webp${addWidth}`;
};

export const getApiFootballtPlayerImage = (id) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;
    return `${process.env.NEXT_PUBLIC_DEFAULT_IMAGES}/players/${id}.png${addWidth}`;
};

export const getTeamCamisetaImageById = (id) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;
    return `${process.env.NEXT_PUBLIC_IMAGES}/camisetas/${id}.png${addWidth}`;
};

export const getImagenEntrenador = (id) => {
    const addWidth = `?width=${playerSize}&height=${playerHeightSize}`;
    return `${process.env.NEXT_PUBLIC_IMAGES}/entrenadores/${id}.png${addWidth}`;
};

export const getFantasyImage = (fantasy, width) => {
    let icon;
    switch (fantasy) {
        case FANTASY.BIWENGER:
            return <BiwengerIcon width={width} />;
        case FANTASY.FANTASY:
            return <FantasyLaLigaIcon iconWidth={width} />;
        default:
            icon = <AnaliticaFantasyIcon width={width} />;
    }

    return icon;
};
