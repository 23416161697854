import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale, // Necesario para gráficos de línea
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getDate_dd_MM } from '@/helpers/datetime-helper';

// Registra solo los módulos necesarios para gráficos de línea
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const AreaChartComponent = ({ marketValues, player }) => {
    const [marketValuesSorted, setMarketValuesSorted] = useState(null);

    const getData = () => {
        const labels = marketValuesSorted?.map((x) => getDate_dd_MM(x.created));
        const data = {
            labels: labels,
            datasets: [
                {
                    fill: true,
                    label: player?.information?.nickname ?? player?.information?.name,
                    data: marketValuesSorted?.map((x) => x.marketValue),
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
            ],
        };
        return data;
    };

    const getOptions = () => {
        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: `Últimos ${marketValuesSorted?.length ?? 0} valores de mercado de La Liga Fantasy`,
                },
            },
        };
        return options;
    };

    useEffect(() => {
        if (marketValues) {
            setMarketValuesSorted(marketValues.sort((a, b) => new Date(a.created) - new Date(b.created)));
        }
    }, [marketValues]);

    return <Line options={getOptions()} data={getData()} />;
};

export default AreaChartComponent;
