import { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, useTheme } from '@mui/material';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import { getIcon } from '../home/home-activities';
import CustomAlertActividades from './custom-alert-actividad';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import axiosApiInstance from '@/api/shared/axios-instance';
import URL_FACTORY from '@/helpers/url-helper';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ActividadBanner = () => {
    const { state, dispatch } = useContext(Context);
    const { activities } = state;
    const router = useRouter();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [waitTime, setWaitTime] = useState(false);

    const [localActivities, setLocalActivities] = useState([]);

    const maxSteps = activities?.activities?.length ?? 0;

    const getActivitiesData = () => {
        axiosApiInstance
            .post(URL_FACTORY.GetActivities, { skip: activities.skip, take: activities.take })
            .then((response) => {
                setLocalActivities(response.data.activities);
                dispatch({
                    type: REDUCERS.UPDATE_ACTIVITIES,
                    payload: {
                        ...activities,
                        activities: [...response.data.activities],
                        shouldRefresh: false,
                    },
                });
            });
    };

    useEffect(() => {
        if (waitTime) {
            if (activities.activities.length > 0) {
                setLocalActivities(activities.activities);
            } else {
                getActivitiesData();
            }
            setWaitTime(false);
        }
    }, [activities, waitTime]);

    useEffect(() => {
        setTimeout(() => {
            setWaitTime(true);
        }, 500);
    }, []);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box className="actividad-banner-container" style={{ backgroundColor: '#e8f4fd' }}>
            {maxSteps > 0 ? (
                <div>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        interval={100000}
                    >
                        {localActivities?.map((activity, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <CustomAlertActividades
                                        severity="info"
                                        text={activity.title}
                                        icon={getIcon(activity)}
                                        onClick={() => router.push(Rutas.ACTIVIDADES)}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                </div>
            ) : null}
        </Box>
    );
};

export default ActividadBanner;
