import { useEffect } from 'react';
import Layout from '@/components/shared/layout';
import '@/styles/base.scss';
import { Provider } from '@/context';
import SpinnerLayout from '@/components/shared/spinner-layout';

export default function App({ Component, pageProps }) {
    // Este efecto desregistra el service worker cuando la aplicación carga
    useEffect(() => {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    registrations.forEach((registration) => {
                        registration.unregister();
                    });
                });
            });
        }
    }, []);

    return (
        <section className={`analitica-fantasy-site`}>
            <Provider>
                <Layout>
                    <SpinnerLayout>
                        <Component {...pageProps} />
                    </SpinnerLayout>
                </Layout>
            </Provider>
        </section>
    );
}
