import { useEffect, useRef, useState } from 'react';

const VideooRefresher = () => {
    const [timeInView, setTimeInView] = useState(0); // Tiempo en que el div está visible
    const [elapsedTime, setElapsedTime] = useState(0); // Tiempo total desde el último refresh
    const [isReadyToRefresh, setIsReadyToRefresh] = useState(false); // Marca si está listo para refrescar
    const divRef = useRef(null); // Referencia al div del anuncio
    const viewIntervalRef = useRef(null); // Referencia para el intervalo de visibilidad
    const totalIntervalRef = useRef(null); // Referencia para el intervalo total
    const viewTimeLimit = 2; // Tiempo mínimo en segundos que el div debe estar visible
    const totalTimeLimit = 30; // Tiempo total en segundos para refrescar

    // Función para refrescar el ad unit utilizando el div hijo
    const refreshAdUnit = () => {
        if (typeof googletag !== 'undefined' && googletag?.cmd) {
            googletag.cmd.push(function () {
                if (googletag?.pubads) {
                    // Buscar el primer hijo del div que contiene el anuncio
                    const adContainerDiv = divRef.current?.querySelector('div[data-google-query-id]');

                    if (adContainerDiv) {
                        const adContainerId = adContainerDiv.getAttribute('id'); // Obtener el ID del div hijo
                        const slots = googletag.pubads().getSlots();

                        // Buscar el slot que coincida con el ID del div hijo
                        const slot = slots.find((slot) => slot.getSlotElementId() === adContainerId);

                        if (slot) {
                            console.log('Slot encontrado y refrescado:', slot.getSlotElementId());
                            googletag.pubads().refresh([slot]);
                        } else {
                            console.error(
                                'No se encontró ningún slot correspondiente al div hijo con id:',
                                adContainerId
                            );
                        }
                    } else {
                        console.error(
                            'No se encontró el div hijo con el atributo data-google-query-id para hacer el refresh.'
                        );
                    }
                } else {
                    console.error('googletag.pubads no está definido.');
                }
            });
        } else {
            console.error('googletag o googletag.cmd no está definido.');
        }
    };

    // Inicia los contadores cuando el componente se monta
    useEffect(() => {
        // Contador total de 30 segundos
        if (!totalIntervalRef.current) {
            totalIntervalRef.current = setInterval(() => {
                setElapsedTime((prevTime) => prevTime + 1);
            }, 1000); // Incrementa cada 1 segundo
        }

        return () => {
            // Limpiar intervalos cuando el componente se desmonta
            clearInterval(viewIntervalRef.current);
            clearInterval(totalIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        // Observador de visibilidad para el div
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Si el div está visible, comenzar a contar el tiempo visible
                        if (!viewIntervalRef.current) {
                            viewIntervalRef.current = setInterval(() => {
                                setTimeInView((prevTime) => prevTime + 1);
                            }, 1000); // Incrementa cada 1 segundo
                        }

                        // Si está listo para refrescar y es visible, refrescar ahora
                        if (isReadyToRefresh) {
                            refreshAdUnit();
                            setIsReadyToRefresh(false); // Resetear el flag de refresco
                        }
                    } else {
                        // Si el div deja de estar visible, detener el contador de visibilidad
                        clearInterval(viewIntervalRef.current);
                        viewIntervalRef.current = null;
                        setTimeInView(0); // Reiniciar el contador de visibilidad cuando deja de estar visible
                    }
                });
            },
            { threshold: 0.5 } // Al menos el 50% del div debe estar visible
        );

        if (divRef.current) {
            observer.observe(divRef.current); // Observar el div
        }

        return () => {
            if (divRef.current) observer.unobserve(divRef.current);
        };
    }, [isReadyToRefresh]);

    useEffect(() => {
        // Verificar si ambas condiciones se cumplen:
        if (elapsedTime >= totalTimeLimit && timeInView >= viewTimeLimit) {
            setIsReadyToRefresh(true); // Marcar como listo para refrescar en la siguiente visibilidad
            setElapsedTime(0); // Reiniciar el tiempo total
        }
    }, [elapsedTime, timeInView]);

    return (
        <div className="af-digitalgreen-parent_4 af-digitalgreen-parent">
            <div className="af-digitalgreen-ad-text">Anuncio</div>
            <div className='af-digitalgreen-ad' id="af_digitalgreen_4" ref={divRef}></div>
        </div>
    );
};

export default VideooRefresher;
