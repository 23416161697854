export const executeVideooTvLogicWithRetries = (attempt = 0) => {
    const retryTimes = [100, 1000, 2000, 3000, 4000]; // Tiempos de retry en ms

    // Buscar todos los divs que empiecen con af_digitalgreen_
    const slotDivs = document.querySelectorAll('[id^="af_digitalgreen_"]');

    // Si no se encuentran divs o window.displaySlots no está listo, hacer retry
    if (
        slotDivs.length === 0 ||
        typeof window.displaySlots !== 'function' ||
        typeof googletag === 'undefined' ||
        typeof googletag.display !== 'function'
    ) {
        if (attempt < retryTimes.length) {
            setTimeout(() => {
                executeVideooTvLogicWithRetries(attempt + 1); // Intentar nuevamente
            }, retryTimes[attempt]);
        }
    } else {
        // Si los divs están disponibles y window.displaySlots está listo, ejecuta la lógica
        const divIds = Array.from(slotDivs).map((div) => div.id); // Obtener los IDs de los divs
        window.displaySlots(divIds); // Ejecutar displaySlots con los IDs encontrados
    }
};
