import { useContext, useEffect, useState } from 'react';
import { AppBar, Tab, Tabs, useTheme } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import MaterialTabPanel from '@/components/shared/material/tab-panel';
import MaterialTabProps from '@/components/shared/material/material-tab-props';
import AreaChartComponent from '@/components/shared/charts/AreaChartComponent';
import PlayerDataTable from '@/components/page-components/market-values/player-data-table';
import { Context } from '@/context';
import { FANTASY } from '@/helpers/biwenger-helper';

const MercadoTabComponent = ({ player }) => {
    const [marketValues, setMarketValues] = useState(null);
    const { state } = useContext(Context);
    const { preferences } = state;
    const [value, setValue] = useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {
        let values = player?.marketValue?.marketValues;

        if (preferences.fantasy === FANTASY.BIWENGER) {
            values = player?.biwengerMarketValue?.marketValues;
        }

        if (values) {
            setMarketValues(values);
        }
    }, [player, preferences.fantasy]);

    return (
        <div>
            <AppBar
                position="static"
                sx={{
                    background: '#f5f7f9',
                    color: '#000',
                    boxShadow: 'none',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    TabIndicatorProps={{
                        sx: {
                            backgroundColor: 'tabsDecorator.main',
                        },
                    }}
                    centered
                >
                    <Tab label="Tabla" {...MaterialTabProps(0)} />
                    <Tab label="Gráfica" {...MaterialTabProps(1)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <MaterialTabPanel value={value} index={0} dir={theme.direction}>
                    {marketValues && <PlayerDataTable marketValues={marketValues} player={player} />}
                </MaterialTabPanel>
                <MaterialTabPanel value={value} index={1} dir={theme.direction}>
                    {marketValues && <AreaChartComponent marketValues={marketValues} player={player} />}
                </MaterialTabPanel>
            </SwipeableViews>
        </div>
    );
};

export default MercadoTabComponent;
