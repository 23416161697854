export const adsHelper = {
    addAdsense: function (attempt = 0) {
        const retryTimes = [100, 1000, 2000, 3000, 4000]; // Tiempos de retry en ms
        const maxAttempts = retryTimes.length; // Máximo de intentos

        setTimeout(() => {
            // Verificar si el script de AdSense está cargado
            if (typeof window.adsbygoogle === 'undefined') {
                if (attempt < maxAttempts) {
                    adsHelper.addAdsense(attempt + 1);
                }
                return;
            }

            let ads = document.getElementsByClassName('adsbygoogle');
            let adsInitialized = 0;

            // Intentar inicializar los anuncios
            for (let i = 0; i < ads.length; i++) {
                if (!ads[i].hasAttribute('data-adsbygoogle-status')) {
                    try {
                        (adsbygoogle = window.adsbygoogle || []).push({});
                        adsInitialized++;
                    } catch (e) {}
                }
            }

            // Si no se encontraron anuncios o no se inicializó ninguno y aún hay intentos disponibles
            if (adsInitialized === 0 && attempt < maxAttempts) {
                adsHelper.addAdsense(attempt + 1);
            }
        }, retryTimes[attempt]);
    },
};
