import DialogContent from '@mui/material/DialogContent';
import confetti from 'canvas-confetti';
import { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import PlayerInfoCardStatus from '../shared/player-infocard-status';
import CustomImageComponent from '../shared/custom/custom-image';
import PositionBox from '../shared/position-box';
import FantasySelectorComponent from './fantasy-selector-tabs';
import MasterPlayerFantasy from './master-player-fantasy';
import NextRivalsTabsComponent from './next-rivals-tabs';
import URL_FACTORY from '@/helpers/url-helper';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import { getMasterBigPhotoById, getTeamImageById } from '@/helpers/images-helper';
import { formatDateToDDMMYYYY, getAgeFromDate, isBirthdayToday } from '@/helpers/datetime-helper';

const MasterPlayerCardComponent = ({ player, classes, hideStatus, hideNextRival, hideFantasySelector }) => {
    const { dispatch } = useContext(Context);

    const getTeamImage = (width) => {
        if (player?.team?.id > 0) {
            return getTeamImageById(player?.team?.id);
        }

        if (player?.fantasyPlayer?.team?.slug) {
            return URL_FACTORY.GetTeamImageLogo(player?.fantasyPlayer?.team?.slug, width ?? 30);
        }

        return player?.team?.logo;
    };

    const getConvocatoriaSeleccion = (player) => {
        if (player?.convocatoriaSeleccion?.isConvocado) {
            return (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '16px',
                            background: '#fdeded',
                            padding: '4px 8px',
                        }}
                    >
                        <div>
                            <Typography>Convocado con: </Typography>
                        </div>
                        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <CustomImageComponent
                                src={URL_FACTORY.GetCountryImage(
                                    player?.convocatoriaSeleccion?.countryCode,
                                    30
                                )}
                                width={20}
                                height={20}
                                alt="selección"
                                style={{ borderRadius: '50%' }}
                            />
                            <Typography style={{ margin: '0' }}>
                                {player?.convocatoriaSeleccion?.countryName}
                            </Typography>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const getPlayerInfoGeneral = (player) => {
        return (
            <div className={`player-info-card__info`}>
                <div className="player-info-card__info-line">
                    <Box className="player-info-card__text">
                        <PositionBox positionId={player?.information?.positionId} fontSize={13} />
                    </Box>
                </div>

                <Typography className="player-info-card__text">
                    Edad:
                    <span style={{ marginLeft: '4px' }}>
                        {getAgeFromDate(player?.information?.birth?.date)}
                        <i style={{ fontSize: '12px', marginLeft: '2px' }}>
                            ({formatDateToDDMMYYYY(player?.information?.birth?.date)})
                        </i>
                    </span>
                </Typography>

                <Typography className="player-info-card__text">
                    Estatura: <span style={{ marginLeft: '4px' }}>{player?.information?.height}</span>
                </Typography>

                <Typography className="player-info-card__text">
                    Peso: <span style={{ marginLeft: '4px' }}>{player?.information?.weight}</span>
                </Typography>
                <Typography className="player-info-card__text">
                    Nacimiento:{' '}
                    <span style={{ marginLeft: '4px' }}>
                        {player?.information?.birth?.place} - {player?.information?.birth?.country}
                    </span>
                </Typography>

                <Box className="player-info-card__text">
                    <CustomImageComponent
                        src={getTeamImage()}
                        alt={'jugador'}
                        width={30}
                        height={30}
                        style={{ marginRight: '5px' }}
                    />
                    <Typography style={{ fontSize: '14px' }}>{player?.team?.name}</Typography>
                </Box>
            </div>
        );
    };

    const launchConfetti = () => {
        var duration = 3 * 1000;
        var end = Date.now() + duration;

        (function frame() {
            // launch a few confetti from the left edge
            confetti({
                particleCount: 7,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                zIndex: 999999999999999,
            });
            // and launch a few from the right edge
            confetti({
                particleCount: 7,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                zIndex: 999999999999999,
            });

            // keep going until we are out of time
            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        })();
    };

    useEffect(() => {
        const isPlayerBirthdayToday = isBirthdayToday(player?.information?.birth?.date);
        if (!isPlayerBirthdayToday) return;
        launchConfetti();
    }, [player?.information?.birth?.date]);

    return (
        <div className={`master-player-card `}>
            <DialogContent>
                <div>
                    <div className={`${classes ?? ''} player-info-card`}>
                        <div
                            className="player-info-card__image"
                            onClick={() => dispatch({ type: REDUCERS.HIDE_PLAYER_PROFILE })}
                        >
                            <CustomImageComponent
                                src={getMasterBigPhotoById(player?.information?.id)}
                                alt={`${player?.information?.name} - fantasy - ${player?.fantasyPlayer?.team?.name}`}
                                width={128}
                                height={128}
                                priority={true}
                            />
                            <Typography className="player-info-card__name">
                                {player?.information?.name}
                            </Typography>
                        </div>

                        {getPlayerInfoGeneral(player)}
                    </div>
                    {hideStatus !== true && <PlayerInfoCardStatus player={player} />}
                    {hideNextRival !== true && <NextRivalsTabsComponent player={player} />}
                    {hideFantasySelector !== true && <FantasySelectorComponent />}

                    <MasterPlayerFantasy player={player} />
                    {getConvocatoriaSeleccion(player)}
                </div>
            </DialogContent>
        </div>
    );
};

export default MasterPlayerCardComponent;
