// services/playerService.js
import URL_FACTORY from '@/helpers/url-helper';
import axiosApiInstance from '@/api/shared/axios-instance';
import { REDUCERS } from '@/constants/reducers';

export const fetchPlayersAndDispatch = async (dispatch, playerProfile) => {
    try {
        // Si los jugadores ya están cargados, no hacer nada
        if (playerProfile.allPlayers?.length > 0) {
            return;
        }

        // Si no están cargados, pedir los jugadores a la API
        const url = URL_FACTORY.GetAllPlayers;
        const response = await axiosApiInstance.get(url);

        // Guardar los jugadores en el contexto usando el dispatch
        const players = response?.data?.players || [];
        dispatch({ type: REDUCERS.SET_ALL_PLAYERS, payload: players });
    } catch (error) {
        console.error('Error fetching players:', error);
        throw error;
    }
};
