import { getPlayerPositionColor, getPlayerPositionShort } from '@/helpers/players-helper';

const PositionBox = ({ positionId, onClick, styles, fontSize }) => {
    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className="position-color-box-container" onClick={handleOnClick}>
            <div
                className={`position-color-${getPlayerPositionColor(positionId)} position-color-box`}
                style={{ ...styles }}
            >
                <div style={{ fontSize: fontSize ?? '8px' }}>{getPlayerPositionShort(positionId)}</div>
            </div>
        </div>
    );
};

export default PositionBox;
