import { getFromLocalStorage } from './cookies';
import { SITE_USER_COOKIE } from '@/constants/site';
import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';

export const isPremiumUser = () => {
    const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
    const data = JSON.parse(dataFromCookies);

    if (data && data.subscriptionType !== SUBSCRIPTION_TYPE.FREE) {
        return true;
    }

    return false;
};

export const cleanTitle = (title) => {
    return title
        .normalize('NFD') // Elimina tildes
        .replace(/[\u0300-\u036f]/g, '') // Remueve los diacríticos
        .replace(/[^a-zA-Z0-9\s]/g, '') // Remueve caracteres especiales
        .replace(/\s+/g, '_') // Reemplaza espacios con guiones bajos
        .toLowerCase(); // Convierte a minúsculas
};
