import { useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Fragment, useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import MasterPlayerModalComponent from '../master-player-modal';
import FantasyHistoricModalComponent from '../master-player-modal/fantasy-historic-modal';
import { executeVideooTvLogicWithRetries } from '../ads-components/videoo/videoo-helper';
import VideooRefresher from '../ads-components/videoo/videoo-refresher';
import VideooAncla from '../ads-components/videoo/videoo-ancla';
import PwaBanner from '../add-to-home-screen/pwa-banner';
import FooterSection from './footer';
import DesktopNavigationComponent from './desktop-navigation';
import TeamLogoComponent from './team-logo';
import LeftDrawerComponent from './left-drawer-component';
import LazyLoadWrapper from './wrappers/lazy-load-wrapper';
import MobileNavigationComponent from './mobile-navigation';
import RightDrawerComponent from './right-drawer-component';
import ActividadBanner from './actividad-banner';
import TwitchLive from './twitch-live';
import SocialComponent from './social';
import JugadoresNavigationComponent from './jugadores-navigation-component';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import axiosApiInstance from '@/api/shared/axios-instance';
import URL_FACTORY from '@/helpers/url-helper';
import { SITE_USER_COOKIE } from '@/constants/site';
import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';
import { getFromLocalStorage } from '@/helpers/cookies';
import { adsHelper } from '@/helpers/ads-helper';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#01aa4d',
        },
        disabled: {
            main: '#9e9e9e',
        },
        error: {
            main: '#d32f2f',
        },
        warning: {
            main: '#ed6c02',
        },
        delete: {
            main: '#fff',
            contrastText: '#d32f2f',
        },
        link: {
            main: '#0d6efd',
        },
        analitica: {
            main: '#22272F',
        },
        tabs: {
            main: '#22272F',
        },
        white: {
            main: '#fff',
        },
        tabsDecorator: {
            main: '#1976d2',
        },
        white: {
            main: '#fff',
        },
        black: {
            main: '#000',
        },
        risks: {
            veryHigh: 'rgb(242 127 127)',
            high: 'rgb(208 172 108)',
            medium: '#dddddd',
            low: 'rgb(148 191 148)',
            veryLow: 'rgb(192 216 237)',
        },
        minutes: {
            veryHigh: '#2196f3',
            high: '#0C6',
            medium: '#ccc',
            low: '#F90',
            veryLow: '#d32f2f',
        },
        competitions: {
            laLiga: '#ff4b44',
            fifaFriendlies: '#4db7ad',
            championsLeague: '#1d6fb1',
            supercopaEspaña: '#F66A03',
            copaRey: 'rgb(229 184 105)',
            europaLeague: '#1a892d',
            euroClasificacion: '#158ec0',
            defaultColor: '#69b59f',
        },
    },
    typography: {
        allVariants: {
            fontFamily: `'Roboto Condensed', sans-serif`,
            textTransform: 'none',
            fontSize: 16,
        },
    },
});

const Layout = (props) => {
    const { state, dispatch } = useContext(Context);
    const { app, user } = state;
    const isXXL = useMediaQuery('(min-width: 1400px)');
    const [rightDrawer, setRightDrawer] = useState(false);
    const [leftDrawer, setLeftDrawer] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const [scriptAdded, setScriptAdded] = useState(false);
    const router = useRouter();

    useEffect(() => {
        dispatch({ type: REDUCERS.UPDATE_USER, payload: { currentUrl: router?.asPath } });
    }, [router?.asPath]);

    const handleCloseLeftDrawer = () => {
        setLeftDrawer(false);
    };

    const handleCloseRightDrawer = () => {
        setRightDrawer(false);
    };

    const handleHamburguerMenu = () => {
        setRightDrawer(false);
        setLeftDrawer(!leftDrawer);
    };

    const handlePartidosMenu = () => {
        setRightDrawer(!rightDrawer);
        setLeftDrawer(false);
    };

    const getFixturesDrawer = () => {
        axiosApiInstance
            .get(URL_FACTORY.GET_URL_RecentFixtures)
            .then((response) => {
                dispatch({
                    type: REDUCERS.UPDATE_FIXTURES_ROUND,
                    payload: {
                        rounds: response.data.rounds,
                        activeRoundIndex: response.data.activeIndex,
                    },
                });
            })
            .catch(() => {});
    };

    const getNavigationMenu = () => {
        if (isXXL) {
            return (
                <DesktopNavigationComponent
                    logged={user.logged}
                    isAdmin={user.isAdmin}
                    handlePartidosMenu={handlePartidosMenu}
                    handleHamburguerMenu={handleHamburguerMenu}
                />
            );
        } else {
            return (
                <MobileNavigationComponent
                    logged={user.logged}
                    isAdmin={user.isAdmin}
                    handleHamburguerMenu={handleHamburguerMenu}
                    handlePartidosMenu={handlePartidosMenu}
                />
            );
        }
    };

    const getLeftDrawer = () => {
        return <LeftDrawerComponent anchor="left" open={leftDrawer} handleClose={handleCloseLeftDrawer} />;
    };

    const getRightDrawer = () => {
        return (
            <RightDrawerComponent
                anchor="right"
                open={rightDrawer}
                handleClose={handleCloseRightDrawer}
                handlePartidosMenu={handlePartidosMenu}
                logged={app.logged}
            />
        );
    };

    useEffect(() => {
        getFixturesDrawer();
    }, []);

    useEffect(() => {
        dispatch({ type: REDUCERS.GET_DATA_FROM_COOKIES });
    }, [user?.subscriptionType]);

    const addAdScripts = () => {
        const isProduction = process.env.NEXT_PUBLIC_ENV_VARIABLE !== 'development';

        if (!isProduction) {
            return;
        }

        const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
        const data = JSON.parse(dataFromCookies);

        if (data && data.subscriptionType !== SUBSCRIPTION_TYPE.FREE) {
            return;
        }

        // Obtén el contenedor de scripts por su ID
        const adsScriptsContainer = document.getElementById('ads-scripts');

        if (adsScriptsContainer) {
            // Script de videoo.tv (sustituye el de AdSense)
            const adsenseScript = document.createElement('script');
            adsenseScript.async = true;
            adsenseScript.src =
                'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1884623822045383';
            adsenseScript.crossOrigin = 'anonymous';
            // adsenseScript.setAttribute('data-overlays', 'bottom');

            const videooScript = document.createElement('script');
            videooScript.defer = true;
            videooScript.id = 'display-library';
            videooScript.setAttribute(
                'data-id',
                '5304a3dd388955b75ae57876347218c2a9e687f78faa26c74b806896a80290cf'
            );
            videooScript.setAttribute('data-cfasync', 'false');
            videooScript.src =
                'https://display.videoo.tv/5304a3dd388955b75ae57876347218c2a9e687f78faa26c74b806896a80290cf.js';

            // Añadir los scripts al contenedor
            adsScriptsContainer.appendChild(adsenseScript);
            adsScriptsContainer.appendChild(videooScript);

            // Actualizar estado de la aplicación
            dispatch({
                type: REDUCERS.UPDATE_APP,
                payload: {
                    ads: true,
                },
            });
        }
    };

    useEffect(() => {
        // Función para verificar el consentimiento actual
        const checkConsent = () => {
            if (window.__tcfapi) {
                window.__tcfapi('getTCData', 2, (tcData, success) => {
                    if (
                        success &&
                        tcData.purpose.consents[1] === true // Verificar que el consentimiento ya está dado
                    ) {
                        setCookiesAccepted(true);
                    }
                });
            }
        };

        // Verificación inicial al cargar la página
        checkConsent();

        // Listener para cambios en el consentimiento
        const handleConsentChange = (tcData, success) => {
            if (
                success &&
                tcData.eventStatus === 'useractioncomplete' &&
                tcData.purpose.consents[1] === true
            ) {
                setCookiesAccepted(true);
            }
        };

        // Escuchar cambios en el consentimiento con el evento addEventListener de TCF
        if (window.__tcfapi) {
            window.__tcfapi('addEventListener', 2, handleConsentChange);
        }

        // Limpieza del listener al desmontar el componente
        return () => {
            if (window.__tcfapi) {
                window.__tcfapi('removeEventListener', 2, handleConsentChange);
            }
        };
    }, []);

    useEffect(() => {
        if (cookiesAccepted) {
            addAdScripts();
            setScriptAdded(true);
        }
    }, [cookiesAccepted]);

    useEffect(() => {
        if (scriptAdded) {
            adsHelper.addAdsense();
            executeVideooTvLogicWithRetries();
        }
    }, [scriptAdded, router.asPath]);

    const getCurrentUrl = (url) => {
        return url?.split('/')?.[1] ?? '';
    };

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <SocialComponent />

                {getNavigationMenu()}
                {getLeftDrawer()}

                {getRightDrawer()}

                <section
                    className={`${
                        user?.subscriptionType !== SUBSCRIPTION_TYPE.FREE
                            ? 'analitica-premium'
                            : 'analitica-free'
                    } main-content url-${getCurrentUrl(router.asPath)}`}
                >
                    <TeamLogoComponent />
                    <JugadoresNavigationComponent />
                    <VideooRefresher />
                    {/* {getLeftFixedAds()} */}

                    {/* {getRightFixedAds()} */}
                    <div className="container">
                        <TwitchLive />
                        <ActividadBanner />
                    </div>
                    <main className="main-website">{props.children}</main>
                    <VideooAncla />

                    <LazyLoadWrapper>
                        <FooterSection />
                    </LazyLoadWrapper>
                </section>

                <ToastContainer />

                <PwaBanner />

                <MasterPlayerModalComponent />
                <FantasyHistoricModalComponent />
                <div id="ads-scripts"></div>
            </ThemeProvider>
        </Fragment>
    );
};

export default Layout;
