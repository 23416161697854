import CustomImageComponent from '../custom/custom-image';

const PredictingManagerIcon = ({ iconWidth, iconHeight }) => {
    return (
        <CustomImageComponent
            src={`${process.env.NEXT_PUBLIC_IMAGES}/logos/predicting-manager.png?width=49&height=50`}
            alt="PM"
            width={iconWidth ?? 25}
            height={iconHeight ?? 25}
        />
    );
};

export default PredictingManagerIcon;
