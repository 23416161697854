import { useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';

const VideooAncla = () => {
    const divRef = useRef(null); // Referencia al div del anuncio
    const totalIntervalRef = useRef(null); // Referencia para el intervalo total de refresco
    const totalTimeLimit = 30; // Tiempo total en segundos para refrescar
    const isMobile = useMediaQuery('(max-width:576px)'); // Detectar si el ancho de la pantalla es 576px o menos

    // Función para refrescar el ad unit utilizando el div hijo con data-google-query-id
    const refreshAdUnit = () => {
        if (typeof googletag !== 'undefined' && googletag?.cmd) {
            googletag.cmd.push(function () {
                if (googletag?.pubads) {
                    // Buscar el primer hijo del div que contiene el anuncio
                    const adContainerDiv = divRef.current?.querySelector('div[data-google-query-id]');

                    if (adContainerDiv) {
                        const adContainerId = adContainerDiv.getAttribute('id'); // Obtener el ID del div hijo
                        const slots = googletag.pubads().getSlots();

                        // Buscar el slot que coincida con el ID del div hijo
                        const slot = slots.find((slot) => slot.getSlotElementId() === adContainerId);

                        if (slot) {
                            console.log('Slot encontrado y refrescado:', slot.getSlotElementId());
                            googletag.pubads().refresh([slot]);
                        } else {
                            console.error(
                                'No se encontró ningún slot correspondiente al div hijo con id:',
                                adContainerId
                            );
                        }
                    } else {
                        console.error(
                            'No se encontró el div hijo con el atributo data-google-query-id para hacer el refresh.'
                        );
                    }
                } else {
                    console.error('googletag.pubads no está definido.');
                }
            });
        } else {
            console.error('googletag o googletag.cmd no está definido.');
        }
    };

    useEffect(() => {
        // Solo ejecutar la lógica si es en mobile (576px o menos)
        if (isMobile) {
            // Iniciar el contador total de 30 segundos desde el último refresh
            if (!totalIntervalRef.current) {
                totalIntervalRef.current = setInterval(() => {
                    refreshAdUnit(); // Refrescar el anuncio cada 30 segundos
                }, totalTimeLimit * 1000); // 30 segundos en milisegundos
            }
        }

        return () => {
            // Limpiar intervalos cuando el componente se desmonta
            clearInterval(totalIntervalRef.current);
        };
    }, [isMobile]);

    return (
        isMobile && ( // Solo renderizar en dispositivos móviles
            <div className="videoo-ancla-container" ref={divRef}>
                <div id="analiticafantasy.com_catfish" className="ad-slot"></div>
                <div className="ad-label">Publicidad</div>
            </div>
        )
    );
};

export default VideooAncla;
